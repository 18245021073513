import { Component, ChangeDetectionStrategy, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { LoaderService } from 'src/app/_services/loader.service';
import { Subject } from 'rxjs';
import { ThemeService } from 'src/app/_services/theme.service';

@Component({
  selector: 'cfe-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  standalone : false,
  changeDetection: ChangeDetectionStrategy.OnPush
  
})
export class LoaderComponent implements AfterViewInit {

  isLoading: Subject<boolean> = this.loaderService.isLoading;
  bgClass: string = '';

  constructor(private loaderService: LoaderService, private ref: ChangeDetectorRef,
    private themeservice: ThemeService) {
    this.bgClass = this.themeservice.getMake();
  }

  ngAfterViewInit(): void {
    this.ref.detectChanges();
  }
}