import { Component, OnInit } from '@angular/core';
import { ErrorDialogService } from 'src/app/_services/errordialog.service';
import { EKYCService } from '../../_services/ekyc.service';
import { GetSecurityQuestionRequestViewModel } from 'src/app/e-kyc/_models/getSecurityQuestionRequestViewModel';
import { SubmitSecurityQuestionRequestViewModel } from 'src/app/e-kyc/_models/SubmitSecurityQuestionRequestViewModel';
import { KYCRandomSecQuesAnsOption } from 'src/app/e-kyc/_models/kycRandomSecQuesAnsOption';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ThemeService } from 'src/app/_services/theme.service';
import { Subscription, forkJoin } from 'rxjs';
import { KYCStatusRequestViewModel, KYCStatusResponseViewModel } from '../_models/KYCStatusViewModel';
import { SecurityService } from 'src/app/_services/security.service';
import { environment } from "src/environments/environment";
import { LogService } from 'src/app/_services/log.service';

@Component({
  selector: 'ekyc-mock-securityquestion',
  templateUrl: './securityquestion.component.html',
  styleUrls: ['./securityquestion.component.scss'],
  standalone:false
})
export class SecurityQuestionUIComponent implements OnInit {

  nameFormControl1 = new FormControl('', [
    Validators.required
  ]);
  nameFormControl2 = new FormControl('', [
    Validators.required
  ]);
  nameFormControl3 = new FormControl('', [
    Validators.required
  ]);
  nameFormControl4 = new FormControl('', [
    Validators.required
  ]);
  nameFormControl5 = new FormControl('', [
    Validators.required
  ]);

  refNo: string = '';
  device: string = '';
  sqLabel1: string = '';
  sqLabel2: string = '';
  sqLabel3: string = '';
  sqLabel4: string = '';
  sqLabel5: string = '';
  platform: string = '';

  sqAnsOptionRes1: KYCRandomSecQuesAnsOption[];
  sqAnsOptionRes2: KYCRandomSecQuesAnsOption[];
  sqAnsOptionRes3: KYCRandomSecQuesAnsOption[];
  sqAnsOptionRes4: KYCRandomSecQuesAnsOption[];
  sqAnsOptionRes5: KYCRandomSecQuesAnsOption[];

  sqSelectedAnsOption1: KYCRandomSecQuesAnsOption;
  sqSelectedAnsOption2: KYCRandomSecQuesAnsOption;
  sqSelectedAnsOption3: KYCRandomSecQuesAnsOption;
  sqSelectedAnsOption4: KYCRandomSecQuesAnsOption;
  sqSelectedAnsOption5: KYCRandomSecQuesAnsOption;

  selectedsq1ao: KYCRandomSecQuesAnsOption;
  selectedsq1aoId: number;
  selectedsq2ao: KYCRandomSecQuesAnsOption;
  selectedsq2aoId: number;
  selectedsq3ao: KYCRandomSecQuesAnsOption;
  selectedsq3aoId: number;
  selectedsq4ao: KYCRandomSecQuesAnsOption;
  selectedsq4aoId: number;
  selectedsq5ao: KYCRandomSecQuesAnsOption;
  selectedsq5aoId: number;

  isSubmitDisabled: boolean = true;
  is3SQHidden: boolean = false;
  isShowMsgHidden: boolean = true;
  isShowCardHidden: boolean = false;
  sqType: string = '';
  bgClass: string = '';
  sub = new Subscription;
  btnText: string = 'Submit';
  kycStatus: KYCStatusResponseViewModel;

  constructor(
    private ekycService: EKYCService,
    public errorDialogService: ErrorDialogService,
    private deviceService: DeviceDetectorService,
    private securityService: SecurityService,
    private route: ActivatedRoute,
    public themeservice: ThemeService,
    private logService: LogService,
    public router: Router
  ) {
    this.bgClass = this.themeservice.getMake();
  }

  ngOnInit() {
    const isMobile = this.deviceService.isMobile() || this.deviceService.isTablet;
    if (!isMobile)
      this.navigate('showmsg', 'nonmobiledevice');

    this.route.queryParams.subscribe(params => {
      this.refNo = params['id'];
      this.device = params["device"];
    });

    if (this.refNo == undefined || this.refNo == null || this.refNo.length < 8) {
      this.navigate('showmsg', 'genericerr');
    }

    //get JWT Token to cater from PC
    forkJoin([this.securityService.updateJWTToken("eKYC")]).subscribe(
      ([ekycResp]) => {
        if (ekycResp === true) {
          this.validateData();
        } else {
          let data = {
            Status: "Failed",
            Message: `${environment.Generic_Error_Message}`
          };

          this.errorDialogService.openDialog(data);
        }
      }
    );
  }
  
  validateData() {
    let request = new KYCStatusRequestViewModel();
    request.ScreeningNo = this.refNo;
    this.ekycService.getKYCStatus(request).subscribe(resp => {
      if (!resp.isSucceed || resp.data == null) {
        this.navigate('showmsg', resp.message == '' ? 'genericerr' : resp.message);
        return;
      }
      this.kycStatus = resp.data;
      this.logService.setLogRocketUserName(this.kycStatus.customerName, this.kycStatus.customerName);
      if (this.kycStatus.status <= 20) {
        this.navigate('ocr');
      }
      else if (this.kycStatus.status == 30) {
        this.getRandomSQ("FiveRandomSQ");
      }
      else if (this.kycStatus.status == 40) {
        this.getRandomSQ("ThreeRandomSQ");
      }
      else if (this.kycStatus.status == 50) {
        this.navigate('showmsg', 'sqsuccess');
      }
      else if (this.kycStatus.status >= 60) {
        this.navigate('showmsg', 'error' + resp.statusCode);
      }
    });
  }

  getRandomSQ(stype: string) {
    this.isSubmitDisabled = true;
    this.btnText = 'Submit';
    this.sqType = stype;
    let request = new GetSecurityQuestionRequestViewModel();
    request.refNo = this.refNo;
    request.sqType = stype;
    let sqcounter = 1;

    if (this.sqType == "ThreeRandomSQ")
      this.is3SQHidden = true;
    else 
      this.is3SQHidden = false;

    this.ekycService.getrandomsecques(request)
      .subscribe(randomSQList => {

        randomSQList.data.forEach(row => {
          if (sqcounter === 1) {
            this.sqLabel1 = row.question;
            request.questionId = row.questionId;            
            this.sqAnsOptionRes1 = row.securityQuestionAnswer;
          }
          else if (sqcounter === 2) {
            this.sqLabel2 = row.question;
            request.questionId = row.questionId;            
            this.sqAnsOptionRes2 = row.securityQuestionAnswer;
          }
          else if (sqcounter === 3) {
            this.sqLabel3 = row.question;
            request.questionId = row.questionId;            
            this.sqAnsOptionRes3 = row.securityQuestionAnswer;
          }
          if (sqcounter === 4) {
            this.sqLabel4 = row.question;
            request.questionId = row.questionId;            
            this.sqAnsOptionRes4 = row.securityQuestionAnswer;
          }
          else if (sqcounter === 5) {
            this.sqLabel5 = row.question;
            request.questionId = row.questionId;            
            this.sqAnsOptionRes5 = row.securityQuestionAnswer;
          }
          sqcounter = sqcounter + 1;
        })
      });
  }

  onSQ1Select(selectedsq1ao) {
    if (selectedsq1ao) {
      const sqAnsOption1 = new KYCRandomSecQuesAnsOption();
      sqAnsOption1.kyccbSecQuestionId = selectedsq1ao.kyccbSecQuestionId;
      sqAnsOption1.questionId = selectedsq1ao.questionId;
      sqAnsOption1.answerOptionId = selectedsq1ao.answerOptionId;
      sqAnsOption1.answerOptionValue = selectedsq1ao.answerOptionValue;
      sqAnsOption1.selected = "Y";
      this.sqSelectedAnsOption1 = sqAnsOption1;

      this.selectedsq1ao = selectedsq1ao;
      this.selectedsq1aoId = selectedsq1ao.answerOptionId;
    }
    this.checkAnswerSelected();
  }

  onSQ2Select(selectedsq2ao) {
    if (selectedsq2ao) {
      const sqAnsOption2 = new KYCRandomSecQuesAnsOption();
      sqAnsOption2.kyccbSecQuestionId = selectedsq2ao.kyccbSecQuestionId;
      sqAnsOption2.questionId = selectedsq2ao.questionId;
      sqAnsOption2.answerOptionId = selectedsq2ao.answerOptionId;
      sqAnsOption2.answerOptionValue = selectedsq2ao.answerOptionValue;
      sqAnsOption2.selected = "Y";
      this.sqSelectedAnsOption2 = sqAnsOption2;

      this.selectedsq2ao = selectedsq2ao;
      this.selectedsq2aoId = selectedsq2ao.answerOptionId;
    }
    this.checkAnswerSelected();
  }

  onSQ3Select(selectedsq3ao) {
    if (selectedsq3ao) {
      const sqAnsOption3 = new KYCRandomSecQuesAnsOption();
      sqAnsOption3.kyccbSecQuestionId = selectedsq3ao.kyccbSecQuestionId;
      sqAnsOption3.questionId = selectedsq3ao.questionId;
      sqAnsOption3.answerOptionId = selectedsq3ao.answerOptionId;
      sqAnsOption3.answerOptionValue = selectedsq3ao.answerOptionValue;
      sqAnsOption3.selected = "Y";
      this.sqSelectedAnsOption3 = sqAnsOption3;

      this.selectedsq3ao = selectedsq3ao;
      this.selectedsq3aoId = selectedsq3ao.answerOptionId;
    }
    this.checkAnswerSelected();
  }

  onSQ4Select(selectedsq4ao) {
    if (selectedsq4ao) {
      const sqAnsOption4 = new KYCRandomSecQuesAnsOption();
      sqAnsOption4.kyccbSecQuestionId = selectedsq4ao.kyccbSecQuestionId;
      sqAnsOption4.questionId = selectedsq4ao.questionId;
      sqAnsOption4.answerOptionId = selectedsq4ao.answerOptionId;
      sqAnsOption4.answerOptionValue = selectedsq4ao.answerOptionValue;
      sqAnsOption4.selected = "Y";
      this.sqSelectedAnsOption4 = sqAnsOption4;

      this.selectedsq4ao = selectedsq4ao;
      this.selectedsq4aoId = selectedsq4ao.answerOptionId;
    }
    this.checkAnswerSelected();
  }

  onSQ5Select(selectedsq5ao) {
    if (selectedsq5ao) {
      const sqAnsOption5 = new KYCRandomSecQuesAnsOption();
      sqAnsOption5.kyccbSecQuestionId = selectedsq5ao.kyccbSecQuestionId;
      sqAnsOption5.questionId = selectedsq5ao.questionId;
      sqAnsOption5.answerOptionId = selectedsq5ao.answerOptionId;
      sqAnsOption5.answerOptionValue = selectedsq5ao.answerOptionValue;
      sqAnsOption5.selected = "Y";
      this.sqSelectedAnsOption5 = sqAnsOption5;

      this.selectedsq5ao = selectedsq5ao;
      this.selectedsq5aoId = selectedsq5ao.answerOptionId;
    }
    this.checkAnswerSelected();
  }

  checkAnswerSelected() {
    if (this.selectedsq1ao != null
      && this.selectedsq2ao != null
      && this.selectedsq3ao != null
    ) {
      if (this.sqType == 'FiveRandomSQ' 
      && this.selectedsq4ao != null
      && this.selectedsq5ao != null) {
        this.isSubmitDisabled = false;
      }
      else if (this.sqType == 'ThreeRandomSQ') {
        this.isSubmitDisabled = false;
      }
    }
  }

  submitRandomSQ() {
    if (this.btnText == 'Proceed') {
      if (this.kycStatus.source == 'CFE' && (this.device == 'M' || this.kycStatus.deviceType == 'M')) {
        window.location.href = this.themeservice.getEngageURL() + 'landing?source=CFE&from=kyc&Id=' + this.kycStatus.sourceReferenceNo;
      } else {
        this.navigate('showmsg', 'sqsuccess');
      }
    }
    else if (this.btnText == 'Close') {
      this.navigate('showmsg', 'sqfail');
    }
    else if (this.btnText == 'Retry') {
      this.getRandomSQ("ThreeRandomSQ");
    }
    else if (this.btnText == 'Submit')
    {
      let data: any;
  
      const submitSQReq = new SubmitSecurityQuestionRequestViewModel();
      submitSQReq.RefNo = this.refNo == undefined ? "" : this.refNo;
      submitSQReq.SQType = this.sqType;
      submitSQReq.securityQuestionsAns = [];
      submitSQReq.securityQuestionsAns.push(this.sqSelectedAnsOption1);
      submitSQReq.securityQuestionsAns.push(this.sqSelectedAnsOption2);
      submitSQReq.securityQuestionsAns.push(this.sqSelectedAnsOption3);
      
      if (this.sqType == "FiveRandomSQ") {
        submitSQReq.securityQuestionsAns.push(this.sqSelectedAnsOption4);
        submitSQReq.securityQuestionsAns.push(this.sqSelectedAnsOption5);
      }

      // POST to server
      this.ekycService.submitrandomsq(submitSQReq)
        .subscribe(resp => {
          if (resp.statusCode === 200) {
            data = {
              Status: 'Success',
              Message: resp.messageDetail
            };
            this.errorDialogService.openDialog(data);
            this.btnText = 'Proceed';
          }
          else if (resp.statusCode === 103) {
            data = {
              Status: "Final Attempt",
              Message: "You have one more attempt remaining to proceed to the next steps."
            };
            this.errorDialogService.openDialog(data);
            this.btnText = 'Retry';
          }
          else {
            data = {
              Status: resp.message,
              Message: resp.messageDetail
            };
            this.btnText = 'Close';
            this.errorDialogService.openDialog(data);
          }
      });
    }
  }

  navigate(path, message: string = '') {
    this.router.navigate([path], {
      queryParams: {
        id: this.refNo,
        device: this.device,
        msg: message
      },
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
