import { Component } from '@angular/core';
import { ErrorDialogService } from 'src/app/_services/errordialog.service';
import { ThemeService } from 'src/app/_services/theme.service';
import { LoaderService } from '../../_services/loader.service';

@Component({
  selector: 'ekyc-mock-rescanOCR',
  templateUrl: './rescanocr.component.html',
  styleUrls: ['./rescanocr.component.scss'],
  standalone:false
})
export class RescanOCRMockComponent {

  /* Property goes here */
  loading: boolean;
  waits: string[] = ["5000"];
  public bgClass: string;

  /* Property goes here */
  constructor(
    public errorDialogService: ErrorDialogService,
    public loaderService: LoaderService,
    public themeservice: ThemeService
  ) {
    this.bgClass = this.themeservice.getMake();
  }

  setDelay(times) {
    if (times.length > 0) {
      // Remove the first time from the array
      let wait = times.shift();

      // Wait for the given amount of time
      setTimeout(() => {
        // Call the setDelay function again with the remaining times
        this.setDelay(times);
        this.loaderService.hide();        //hide progress bar
        this.showMsg();
      }, wait);
    }
  }

  showMsg() {
    let data = {
      Status: 'Notification',
      Message: 'Request for OCR Rescan completed!'
    };
    this.errorDialogService.openDialog(data);
  }

  rescanOCR() {
    this.loaderService.show();        //show progress bar
    this.setDelay(this.waits);
  }

  redirectSecurityQuestion() {
    window.location.href = './securityquestion';   //redirect to security question
  }
}