import { Component, OnInit } from '@angular/core';
import { ThemeService } from 'src/app/_services/theme.service';

@Component({
  selector: 'ekyc-expired-link',
  templateUrl: './expired-link.component.html',
  styleUrls: ['./expired-link.component.scss'],
  standalone:false
})
export class ExpiredLinkComponent implements OnInit {

 
  public bgClass : string;

  constructor(
    public themeservice : ThemeService
    ) 
    {
      this.bgClass = this.themeservice.getMake();
    }

  ngOnInit() {
  }

}
