import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ThemeService } from 'src/app/_services/theme.service';

@Component({
  selector: 'ekyc-errordialog',
  templateUrl: './errordialog.component.html',
  styleUrls: ['./errordialog.component.scss'],
  standalone: false
})
export class ErrorDialogComponent {

  bgClass: string = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public themeservice: ThemeService) {
    this.bgClass = this.themeservice.getMake();
  }
}
